<template>
  <div class="container">
    <b-row>
      <b-col class="text-left">
        <h2 class="title-classe">Classe</h2>
      </b-col>
    </b-row>
    <formView />
  </div>
</template>
  
<script>
import formView from "@/components/classe/formView/formView.vue";

export default {
  name: "viewClasse",
  components: {
    formView,
  },
};
</script>


<style>
.title-classe {
  font-weight: 900;
  font-size: 2.5em;
  color: #cf0209;
  font-family: "Segoe_UI_Black_2";
  letter-spacing: 1px;
}

/*  Mobile  */

@media (max-width: 992px) {
  .title-classe {
    font-size: 2em;
  }
}
</style>