<template>
  <div >
    <hr class="hr-M "/>
    <b-row class="py-2">
       <b-col sm="12" md="12" lg="6">
        <div class="form-group fieldContent pb-2">
          <label class="field-title"
            >Desrição
          </label>
          <input
            type="text"
            id="example1"
            class="form-control rounded-0 field-input"
          />
        </div>
      </b-col>

      <b-col sm="12" md="12" lg="8" class="pt-4">
        <div class="form-group fieldContent">
          <label class="field-title"
            >Validade farmácia popular dias
          </label>
          <input
            type="text"
            id="example1"
            class="form-control rounded-0 field-input"
          />
        </div>
      </b-col>
    </b-row>

    <hr class="hr-position" />

    <!-- Button -->
    <b-row class="py-4 position-btn">
      <b-col class="text-left">
        <b-button @click="cancelF()" class="btnVoltar"> Voltar </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: "formViewGeral",
  methods: {
    cancelF() {
      this.$router.push("/classe");
    },
  },
};
</script>

<style>
/*   Buttons   */

.btnVoltar {
  background: #272727 0% 0% no-repeat padding-box;
  opacity: 1;
  color: #fff;
  width: 180px;
  height: 40px;
  font-weight: bold;
  border-radius: 5px;
  margin-left: 5px;
  box-shadow: 6px 6px 8px #53535359;
  font-family: "Segoe_UI_Black_2";
}

.position-btn {
  position: relative;
  top: 25vh;
}



.hr-position{
 width: 70% !important;
  
}

@font-face {
  font-family: "Segoe_UI_Bold";
  src: local("Segoe_UI_Bold"),
    url(~@/assets/font/Segoe_UI_Bold.ttf) format("truetype");
}

@font-face {
  font-family: "Segoe_UI_Black_2";
  src: local("Segoe_UI_Bold"),
    url(~@/assets/font/Segoe_UI_Black_2.ttf) format("truetype");
}

/*   Fields */
.fieldContent {
  margin: 0px !important;
  padding: 0px !important;
  background-color: #e6e6e6;
  text-align: left;
  border-radius: 5px 5px 0px 0px !important;
}

.field-title {
  margin: 0px 0px 0px 6px !important;
  background-color: #e6e6e6;
  color: #272727;

  font-size: 10px;
  font-weight: bold;
  color: #272727;
}

.field-input {
  margin: 0px !important;
  padding: 0px 0px 0px 6px !important;
  height: 37px;
  font-size: 14px;
  font-weight: bold;
  color: #272727;
  text-align: left !important;
  outline: none !important;
  border-width: 0 0 3px !important;
  border-bottom: 3px solid #272727 !important;
  background-color: #e6e6e6;
}

.form-control:focus {
  color: #495057;
  background-color: #e6e6e6;
  outline-style: none;
  box-shadow: none;
  border-color: transparent;
}



/*  Mobile  */

@media (max-width: 992px) {
  .title-form {
    padding-right: 40px;
    color: #cf0209;
    position: relative;
    top: 15px;
  }
  .fieldMpdd {
    padding-top: 10px;
  }

  .hr-M{
  width: 100% !important;
  position: initial;
}

.hr-position {
  width: 100% !important;
  position: initial;
}

  .position-btn {
  top: 20vh;
}

.btnVoltar {
  width: 120px;
  height: 35px;
  font-size: 0.9em;

}




}
</style>